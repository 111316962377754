import styled from "@emotion/styled";
import { Box, BoxProps, SxProps } from "@mui/material";
import ImageRounded from "@mui/icons-material/ImageRounded";
import { imageBoxStyle } from "constants/styles";

interface ImageBoxPropType extends BoxProps {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  coverImg?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
  placeholderIcon?: React.ReactNode;
  sx?: SxProps;
}

const ImageWrapper = styled(Box)<ImageBoxPropType>();

const ImageBox = (props: ImageBoxPropType) => {
  const {
    width = undefined,
    height = undefined,
    maxWidth = undefined,
    maxHeight = undefined,
    coverImg,
    backgroundPosition,
    backgroundSize = "cover",
    children,
    ariaLabel,
    sx,
    placeholderIcon,
    ...otherProps
  } = props;

  const combinedStyle: SxProps = {
    backgroundImage: coverImg && `url(${coverImg})`,
    backgroundColor: !coverImg && "greyVariantAlt.A400",
    backgroundPosition: backgroundPosition,
    backgroundSize: backgroundSize,
    maxWidth: maxWidth || undefined,
    maxHeight: maxHeight || undefined,
    ...imageBoxStyle,
    ...sx,
  };

  return (
    <ImageWrapper
      sx={combinedStyle}
      width={width}
      height={height}
      aria-label={ariaLabel}
      {...otherProps}
    >
      {(!coverImg && placeholderIcon) || (!coverImg && <ImageRounded />)}
      {children}
    </ImageWrapper>
  );
};

export default ImageBox;
