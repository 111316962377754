import { FC } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { pxToRem } from "constants/styles";

type CheckboxWrapperPropType = {
  checked: boolean;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxProps?: CheckboxProps;
} & Partial<FormControlLabelProps>;

const CheckboxWrapper: FC<CheckboxWrapperPropType> = (props) => {
  const { label, checked, name, onChange, checkboxProps, sx, ...otherProps } =
    props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={onChange}
          {...checkboxProps}
        />
      }
      label={label}
      sx={{
        "span.MuiButtonBase-root.MuiCheckbox-root": {
          height: pxToRem(40),
          width: pxToRem(40),
        },
        "span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label":
          {
            ml: pxToRem(12),
            fontSize: pxToRem(12),
            fontWeight: 500,
          },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CheckboxWrapper;
