import { Box, LinearProgress } from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  GridRowSelectionModel,
  GridFilterModel,
  GridColDef,
  GridPinnedColumns,
  GridSortModel,
  GridSortItem,
} from "@mui/x-data-grid-premium";
import { dataGridWrapper } from "constants/styles";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";

export type TablePropType = {
  rows: any;
  columns?: GridColDef[];
  checkboxSelection?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  filterModel?: GridFilterModel;
  loading?: boolean;
  sx?: object;
  handleRowClick?: (
    params: GridRowParams,
    event: MuiEvent,
    callback: GridCallbackDetails
  ) => void;
  handleCheckboxClick?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  selectionModelIds?: Array<number>;
  getRowId?: (row: any) => number;
  children?: React.ReactNode;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  totalRecords?: number;
  isDyanmic?: boolean;
  pinnedColumns?: GridPinnedColumns;
  rowHeight?: number;
  hideHeader?: boolean;
  getRowClassName?: (params: GridRowParams) => string;
  footer?: React.ReactNode;
  sortModel?: GridSortModel;
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void;
  handleFilterModelChange?: (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => void;
} & Partial<DataGridPremiumProps>;

export default function TableComponent(props: TablePropType) {
  const {
    rows = [],
    columns,
    checkboxSelection = true,
    handleRowClick,
    handleCheckboxClick,
    apiRef,
    loading,
    sx,
    selectionModelIds,
    getRowId = (row) => row.id,
    children,
    setPage,
    page = 0,
    totalRecords,
    isDyanmic = false,
    pinnedColumns = {},
    rowHeight = 62,
    hideHeader = false,
    getRowClassName,
    footer,
    sortModel,
    handleSortModelChange,
    handleFilterModelChange,
    ...prop
  } = props;

  const [dataRows, setDataRows] = useState([]);
  const fetchedPages = useMemo(() => new Set(), []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevTotalRecordsRef = useRef<number | undefined>(undefined);
  const prevSortModelRef = useRef<GridSortItem | undefined>(undefined);

  useEffect(() => {
    const shouldUpdateDataRows =
      !isDyanmic ||
      prevTotalRecordsRef.current !== totalRecords ||
      dataRows.length > totalRecords;

    if (shouldUpdateDataRows) {
      setDataRows(rows);
    }

    // Update previous total records
    prevTotalRecordsRef.current = totalRecords;
  }, [isDyanmic, columns, totalRecords, props.columns]);

  const computeDataRows = useCallback(() => {
    if (!fetchedPages.has(page) || !dataRows.length) {
      fetchedPages.add(page);
      if (page === 0) {
        setDataRows(rows);
      } else {
        if (rows.length) {
          setDataRows((prevDataRows) => {
            const newRows = [...prevDataRows, ...rows];
            const uniqueRows = Array.from(new Set(newRows.map(getRowId))).map(
              (id) => newRows.find((row) => getRowId(row) === id)
            );
            return uniqueRows;
          });
        }
      }
    } else if (
      prevSortModelRef.current?.field !== sortModel?.[0]?.field ||
      prevSortModelRef.current?.sort !== sortModel?.[0]?.sort
    ) {
      setDataRows(rows);
    }
  }, [rows, page, getRowId, dataRows.length, fetchedPages]);

  useEffect(() => {
    computeDataRows();
  }, [computeDataRows]);

  const handleRowsScrollEnd = useCallback(() => {
    if (dataRows.length < totalRecords && !loading) {
      if (totalRecords === dataRows.length) {
        return;
      }
      setPage((prevPage) => prevPage + 1);
    }
  }, [dataRows.length, totalRecords, loading, setPage]);

  const memoizedDataRows = useMemo(() => dataRows, [dataRows]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (loading) {
        event.preventDefault();
      }
    };

    const virtualScroller = containerRef.current.querySelector(
      ".MuiDataGrid-virtualScroller"
    );

    if (virtualScroller) {
      virtualScroller.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (virtualScroller) {
        virtualScroller.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  useEffect(() => {
    prevSortModelRef.current = sortModel?.[0];
  }, [rows]);

  return (
    <Box ref={containerRef} sx={{ ...dataGridWrapper, ...sx }}>
      {totalRecords ? (
        <>
          <DataGridPremium
            disableRowSelectionOnClick
            rows={memoizedDataRows}
            columns={columns}
            checkboxSelection={checkboxSelection}
            onRowDoubleClick={handleRowClick}
            onRowSelectionModelChange={handleCheckboxClick}
            apiRef={apiRef}
            loading={loading}
            getRowId={getRowId}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            rowSelectionModel={selectionModelIds}
            pagination={true}
            paginationMode="server"
            autoPageSize={false}
            rowCount={totalRecords}
            onRowsScrollEnd={handleRowsScrollEnd}
            hideFooter={true}
            pinnedColumns={pinnedColumns}
            rowHeight={rowHeight}
            getRowClassName={getRowClassName}
            sx={hideHeader ? dataGridWrapper.hideHeader : {}}
            sortingMode="server"
            sortingOrder={["desc", "asc"]}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            {...prop}
          />
          {children}
        </>
      ) : (
        <>
          <DataGridPremium
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            hideFooter
            checkboxSelection={checkboxSelection}
            onRowDoubleClick={handleRowClick}
            onRowSelectionModelChange={handleCheckboxClick}
            apiRef={apiRef}
            loading={loading}
            getRowId={getRowId}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            rowSelectionModel={selectionModelIds}
            sx={hideHeader ? dataGridWrapper.hideHeader : {}}
            {...prop}
          />
          {children}
        </>
      )}
      {footer}
    </Box>
  );
}
