import {
  GridSortItem,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { APP_ROUTES } from "constants/routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllPersonnels } from "redux/slices/personnel/personnel.action";
import { setSelectedRoles } from "redux/slices/role/role.slice";
import { AppDispatch, RootState } from "redux/store";
import useModal from "utils/hooks/useModal";
import encodeParams from "utils/urlObjectEncoder";

const initialSortModel: GridSortModel = [
  {
    field: "userName",
    sort: "desc",
  } as GridSortItem,
];

const useViewPersonnels = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    error,
    personnels,
    isImportSuccess,
    filteredPersonnels,
    totalRecords,
  } = useSelector((state: RootState) => state.personnel);
  const [page, setPage] = useState(0);
  const [searchParam, setSearchParam] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);

  const { isOpen, toggleModal } = useModal();

  const apiRef = useGridApiRef();
  const handleFilterClick = () => {
    apiRef.current.showFilterPanel();
  };

  const searchHandler = (event) => {
    setSearchParam(event?.target?.value.toLowerCase());
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleCheckboxClick = (ids) => {
    const selectedIds = new Set<string>(ids);
    const selectedRows = personnels.filter((row) =>
      selectedIds.has(String(row.id))
    );
    // TODO: Some code will go in there in upcoming ticket
  };

  const handleFilterModelChange = (filter) => {
    //process filter data and send to api
  };

  const handleViewClick = (id) => {
    const queryParams = {
      id,
    };
    const encodedParams = encodeParams(queryParams);
    history.push(`${APP_ROUTES.ViewSecurityPersonnel}?${encodedParams}`);
  };

  const fetchAllPersonnels = () => {
    dispatch(
      getAllPersonnels({
        page: page + 1,
        field: sortModel[0]?.field,
        sort: sortModel[0]?.sort === "asc" ? 0 : 1,
        search: searchParam,
      })
    );
  };

  useEffect(() => {
    fetchAllPersonnels();
    dispatch(setSelectedRoles([]));
  }, [page, searchParam, sortModel]);

  useEffect(() => {
    setPage(0);
  }, [searchParam, sortModel]);

  useEffect(() => {
    if (isImportSuccess) fetchAllPersonnels();
  }, [isImportSuccess]);

  const handleRouteToCreateUser = () => {
    history.push(APP_ROUTES.EditSecurityPersonnel);
  };

  return {
    isLoading,
    error,
    personnels,
    filteredPersonnels,
    handleFilterClick,
    apiRef,
    searchHandler,
    handleCheckboxClick,
    page,
    setPage,
    totalRecords,
    handleViewClick,
    sortModel,
    isOpen,
    toggleModal,
    handleRouteToCreateUser,
    handleSortModelChange,
    handleFilterModelChange,
  };
};

export default useViewPersonnels;
