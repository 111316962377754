import { ReactNode } from "react";
import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandableHeadingStyle } from "constants/styles";
import useExpandAbleHeading from "./useExpandAbleHeading";

interface ExpandableHeadingInterface extends AccordionProps {
  headingTitle?: String;
  headingChildren?: ReactNode;
  noExpandable?: Boolean;
  noMiddleLine?: boolean;
  buttonContainer?: React.ReactNode;
}

const ExpandableHeadingWrapper = styled(Accordion)<ExpandableHeadingInterface>(
  () => ({})
);

const ExpandableHeading = (props: ExpandableHeadingInterface) => {
  const {
    headingTitle = "Heading",
    headingChildren,
    children = "Content not define",
    noExpandable = false,
    noMiddleLine = false,
    buttonContainer,
    expanded = false,
    ...otherProps
  } = props;

  const { expand, setExpand } = useExpandAbleHeading(expanded);

  return (
    <Box position={"relative"}>
      <ExpandableHeadingWrapper
        sx={{
          ...otherProps.sx,
          ...ExpandableHeadingStyle,
          ...(noExpandable &&
            ExpandableHeadingStyle["&.MuiAccordion-root"].noExpandable),
        }}
        expanded={expand}
        {...otherProps}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            ...(noMiddleLine
              ? {
                  ".MuiAccordionSummary-content::after": {
                    height: "0 !important",
                  },
                }
              : {}),
          }}
          onClick={() => setExpand(!expand)}
        >
          {headingChildren ?? headingTitle}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </ExpandableHeadingWrapper>

      {buttonContainer && (
        <Box sx={ExpandableHeadingStyle["&.MuiAccordion-root"].buttonContainer}>
          {buttonContainer}
        </Box>
      )}
    </Box>
  );
};

export default ExpandableHeading;
