import { LOCAL_STORAGE_KEYS } from "constants/index";
import { APP_ROUTES } from "constants/routes";
import { Redirect, Route } from "react-router-dom";
import AppShell from "AppShell";
import useValidatePermission from "utils/hooks/useValidatePermission";
import { PERMISSION } from "constants/security";

type ProtectedRoutePropType = {
  key: string;
  path: string;
  exact: boolean;
  permissionKey: PERMISSION;
  component: React.FC;
};

const ProtectedRoute = (props: ProtectedRoutePropType) => {
  const { component: Component, permissionKey, ...rest } = props;
  const tokenExist = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

  const { checkPermission } = useValidatePermission();

  const renderAuthenticatedRoute = (routeProps: any) => {
    if (permissionKey && !checkPermission(permissionKey)) {
      return <Redirect to={APP_ROUTES.Home} />;
    }

    return (
      <AppShell>
        <Component {...routeProps} />
      </AppShell>
    );
  };

  return (
    // INFO: Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(routeProps) =>
        tokenExist ? (
          renderAuthenticatedRoute(routeProps)
        ) : (
          <Redirect to={APP_ROUTES.Login} />
        )
      }
    />
  );
};

export default ProtectedRoute;
